<template>
    <el-dialog width="600px" top="40px" title="Lägg till" :visible.sync="visible" :before-close="closeModal" :close-on-click-modal="false" @closed="resetModalState">
        <el-form ref="form" :model="formData" class="px-16" :rules="rules">
            <el-form-item label="Välj år" prop="selectedYear">
                <el-select ref="yearSelect" v-model="formData.selectedYear" class="w-full">
                    <el-option v-for="year in selectableFinancialYears" :key="year.financialYearId" :label="year.startDate + ' - ' + year.endDate" :value="year.financialYearId" />
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" type="default">Stäng</el-button>
            <el-button @click="create" type="primary" :loading="$waiting.is('creating')">Lägg till</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Api from "../annualReport.api";
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        selectableFinancialYears: {
            type: Array,
        },
        clientId: {
            type: String,
        },
    },

    data() {
        return {
            formData: {},
            selectedYear: null,
            financialYearId: null,
            annualReportId: 1,
            rules: {
                selectedYear: [{ required: true, message: "Nödvändig", trigger: "blur" }],
            },
        };
    },

    methods: {
        async create() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$waiting.start("adding");
            try {
                this.financialYearId = this.formData.selectedYear;
                this.annualReportId = await Api.createNewAnnualReport(this.clientId, this.financialYearId);
                this.$router.push({ path: `/annualReport/companyInformation/${this.annualReportId}` });
                this.$emit("refresh");
            } finally {
                this.closeModal();
            }
            this.$waiting.end("adding");
        },
        resetModalState() {
            this.formData = {};
        },

        closeModal() {
            this.$emit("close");
        },

        focusYearSelect() {
            this.$nextTick(() => {
                this.$refs.yearSelect.focus();
            });
        },
    },
};
</script>
